import React from 'react';
import { SkinPageProps } from '../SkinPage';
import skinsStyle from './styles/skins.scss';

const ResponsivePageWithColorBGSkin: React.FC<SkinPageProps> = ({
  id,
  pageDidMount,
  onClick,
  onDblClick,
  children,
}) => {
  return (
    <div
      id={id}
      className={skinsStyle.root}
      ref={pageDidMount}
      onClick={onClick}
      onDoubleClick={onDblClick}
    >
      <div className={skinsStyle.bg} />
      <div>{children()}</div>
    </div>
  );
};

export default ResponsivePageWithColorBGSkin;
